import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { addAllSectionsScrollListener } from "../../utils/sectionScrollListener.js";
var descopNavItems = _toConsumableArray(document.querySelectorAll(".descopMenu__button"));
export var handleDescopNavigation = function handleDescopNavigation() {
  var onScroll = function onScroll(from, to) {
    descopNavItems.forEach(function (item) {
      if (item.dataset.navigation === from) {
        item.classList.remove("active");
      }
      if (item.dataset.navigation === to) {
        item.classList.add("active");
      }
    });
  };
  addAllSectionsScrollListener(onScroll);
};