import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
var SectionListenerCallbacksMap = new Map();
export var addSectionScrollListener = function addSectionScrollListener(identifier, callback) {
  var callbacks = SectionListenerCallbacksMap.get(identifier) || [];
  SectionListenerCallbacksMap.set(identifier, [].concat(_toConsumableArray(callbacks), [callback]));
};
var allSectionsListenerCallbacks = [];
export var addAllSectionsScrollListener = function addAllSectionsScrollListener(callback) {
  allSectionsListenerCallbacks.push(callback);
};
export var dispatchScrollChange = function dispatchScrollChange(from, to) {
  var fromCallbacks = SectionListenerCallbacksMap.get(from);
  fromCallbacks === null || fromCallbacks === void 0 ? void 0 : fromCallbacks.forEach(function (callback) {
    return callback(false);
  });
  var toCallbacks = SectionListenerCallbacksMap.get(to);
  toCallbacks === null || toCallbacks === void 0 ? void 0 : toCallbacks.forEach(function (callback) {
    return callback(true);
  });
  allSectionsListenerCallbacks.forEach(function (callback) {
    return callback(from, to);
  });
};