import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { setCssVariable } from "../../../utils/cssVariables.js";
import { getRandomColor } from "./random.js";
var randomColor = getRandomColor();
var darkModeColors = [["--font-color", "#eee"], ["--bg-color", "#152028"], ["--bg-transparent-layer", "#15202899"], ["--primary-color", randomColor.dark]];
var lightModeColors = [["--font-color", "#222"], ["--bg-color", "#dee4e7"], ["--bg-transparent-layer", "#dee4e799"], ["--primary-color", randomColor.light]];
var schemes = {
  dark: darkModeColors,
  light: lightModeColors
};
export var applyColorScheme = function applyColorScheme(scheme) {
  schemes[scheme].forEach(function (variable) {
    var _variable = _slicedToArray(variable, 2),
      name = _variable[0],
      value = _variable[1];
    setCssVariable(name, value);
  });
};