import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { gsap } from "../../../_snowpack/pkg/gsap.js";
var timeline;
export var openMenu = function openMenu(menu, menuItems) {
  menu.style.display = "block";
  gsap.set(menuItems, {
    opacity: 0,
    x: 20
  });
  timeline = gsap.timeline();
  timeline.to(menu, {
    opacity: 1
  });
  menuItems.forEach(function (item) {
    timeline.to(item, {
      x: 0,
      opacity: 1
    }, "-=0.1");
  });
  menu.classList.remove("mobileMenu--close");
};
export var closeMenu = function closeMenu(menu, menuItems) {
  if (timeline) {
    timeline.pause();
  }
  gsap.to([menu].concat(_toConsumableArray(menuItems)), {
    opacity: 0
  });
  menu.classList.add("mobileMenu--close");
};
export var backgroundTextChange = function backgroundTextChange(item, backgroundText) {
  backgroundText.textContent = item.textContent;
};