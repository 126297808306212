import gsap, { Back } from "../../../../_snowpack/pkg/gsap.js";
import { halfOfCursorSize } from "./index.js";
export var onInteractiveElement = function onInteractiveElement(e, cursorElement, cursorShadowElement) {
  if (e.target instanceof HTMLElement) {
    var rect = e.target.getBoundingClientRect();
    var middleX = (rect.left + rect.right) / 2;
    var middleY = (rect.top + rect.bottom) / 2;
    var scale;
    var customScale = Number(e.target.dataset.cursorScale);
    if (typeof customScale === "number" && !isNaN(customScale)) {
      scale = customScale;
    } else {
      scale = rect.width / 40;
    }
    gsap.to(cursorElement, {
      x: (middleX + e.clientX) / 2 - halfOfCursorSize,
      y: (middleY + e.clientY) / 2 - halfOfCursorSize,
      duration: 0.3,
      opacity: 0.2,
      scale: scale
    });
    gsap.to(cursorShadowElement, {
      opacity: 0
    });
  }
};
export var onNotInteractiveElement = function onNotInteractiveElement(e, cursorElement, cursorShadowElement) {
  gsap.to(cursorElement, {
    x: e.clientX - halfOfCursorSize,
    y: e.clientY - halfOfCursorSize,
    scale: 1,
    duration: 0.2,
    opacity: 1
  });
  gsap.to(cursorShadowElement, {
    x: e.clientX - halfOfCursorSize,
    y: e.clientY - halfOfCursorSize,
    duration: 1.5,
    opacity: 0.4,
    ease: Back.easeOut
  });
};