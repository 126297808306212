var red = {
  dark: "#FC5130",
  light: "#dc300f"
};
var blue = {
  dark: "#30BCED",
  light: "#1891bb"
};
var darkBlue = {
  dark: "#3e4ec4",
  light: "#3e4ec4"
};
var green = {
  dark: "#17d14f",
  light: "#19a242"
};
var orange = {
  dark: "#d17e17",
  light: "#f18f14"
};
var colors = [red, blue, green, orange, darkBlue];
export var getRandomColor = function getRandomColor() {
  var randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};