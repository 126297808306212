var isPerformanceEntryWithType = function isPerformanceEntryWithType(performanceEntry) {
  if (performanceEntry.type) {
    return true;
  }
  return false;
};
export var hasPageBeenRefreshed = function hasPageBeenRefreshed() {
  try {
    var entries = performance.getEntriesByType("navigation");
    var entry = entries[0];
    if (isPerformanceEntryWithType(entry)) {
      return entry.type === "reload";
    }
  } catch (_unused) {
    return false;
  }
};