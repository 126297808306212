import { isSectionId } from "../utils/isSectionId.js";
import { addAllSectionsScrollListener } from "../utils/sectionScrollListener.js";
import { getCurrentSection } from "../features/global/customScroll/index.js";
import * as lazyFeatures from "./lazyFeatures.js";
var state = {
  start: false,
  about: false,
  portfolio: false,
  technologies: false,
  contact: false
};
var isAllSectionLoaded = false;
var getSectionElement = function getSectionElement(sectionId) {
  return document.getElementById(sectionId);
};
var onSection = function onSection(sectionId) {
  if (!isAllSectionLoaded) {
    if ((sectionId === "start" || sectionId === "about") && !state.start) {
      state.start = true;
      var startSection = getSectionElement("start");
      lazyFeatures.background(startSection);
    }
    if ((sectionId === "start" || sectionId === "about" || sectionId === "portfolio") && !state.about) {
      state.about = true;
      var aboutSection = getSectionElement("about");
      lazyFeatures.about(aboutSection);
    }
    if ((sectionId === "about" || sectionId === "portfolio" || sectionId === "technologies") && !state.portfolio) {
      state.portfolio = true;
      var portfolioSection = getSectionElement("portfolio");
      lazyFeatures.portfolio(portfolioSection);
    }
    if ((sectionId === "portfolio" || sectionId === "technologies" || sectionId === "contact") && !state.technologies) {
      state.technologies = true;
      var technologiesSection = getSectionElement("technologies");
      lazyFeatures.technologies(technologiesSection);
    }
    if ((sectionId === "technologies" || sectionId === "contact") && !state.contact) {
      state.contact = true;
      var contactSection = getSectionElement("contact");
      lazyFeatures.contact(contactSection);
    }
    isAllSectionLoaded = Object.values(state).every(function (x) {
      return x;
    });
  }
};
export var handleLazyFeatures = function handleLazyFeatures() {
  addAllSectionsScrollListener(function (from, to) {
    return onSection(to);
  });
  var currentSection = getCurrentSection();
  if (isSectionId(currentSection)) {
    onSection(currentSection);
  }
};