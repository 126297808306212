import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { gsap } from "../../../../_snowpack/pkg/gsap.js";
import { splitText } from "../../../utils/splitText.js";
import { getPrimaryColor } from "../../../utils/primaryColor.js";
import { getCssVariable } from "../../../utils/cssVariables.js";
export var applyTextEffects = function applyTextEffects(classesOfElements) {
  var chars = [];
  classesOfElements.forEach(function (classOfElement) {
    return chars.push.apply(chars, _toConsumableArray(splitText(classOfElement)));
  });
  var onEnter = function onEnter(_char) {
    var tl = gsap.timeline();
    var primaryColor = getPrimaryColor();
    tl.to(_char, {
      scaleY: 1.4,
      color: primaryColor,
      duration: 0.15
    });
    tl.to(_char, {
      scaleX: 1.4,
      duration: 0.15
    });
  };
  var onLeave = function onLeave(_char2) {
    var fontColor = getCssVariable("--font-color");
    gsap.to(_char2, {
      scale: 1,
      color: fontColor,
      duration: 0.3,
      clearProps: "color"
    });
  };
  chars.forEach(function (_char3) {
    _char3.addEventListener("touchstart", function () {
      return onEnter(_char3);
    }, {
      passive: true
    });
    _char3.addEventListener("touchend", function () {
      return onLeave(_char3);
    }, {
      passive: true
    });
    _char3.addEventListener("mouseenter", function () {
      return onEnter(_char3);
    });
    _char3.addEventListener("mouseleave", function () {
      return onLeave(_char3);
    });
  });
};