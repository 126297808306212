import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';
export var handleError = function handleError(e) {
  var pageWrapper = document.querySelector(".wrapper");
  var errorElement = document.querySelector(".error");
  pageWrapper.innerHTML = "";
  errorElement.style.display = "block";
  report(e);
};
var report = function report(e) {
  var errorJSON = JSON.stringify(e, Object.getOwnPropertyNames(e));
  fetch("".concat(__SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL, "/report"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: errorJSON
  });
};