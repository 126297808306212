import { gsap, Linear } from "../../../../_snowpack/pkg/gsap.js";
import { addMediaQueryListener } from "../../../utils/mediaQueryListener.js";
import { onInteractiveElement, onNotInteractiveElement } from "./actions.js";
var cursorElement = document.querySelector(".cursor");
var cursorShadowElement = document.querySelector(".cursorShadow");
export var cursorSize = 50;
export var halfOfCursorSize = cursorSize / 2;
export var handleCustomCursor = function handleCustomCursor() {
  var rotateCursor = gsap.timeline({
    repeat: -1
  });
  rotateCursor.to(cursorElement, {
    rotation: "360",
    duration: 10,
    ease: Linear.easeInOut
  });
  var onMouseMove = function onMouseMove(e) {
    if (e.target instanceof HTMLElement) {
      if (e.target.dataset.cursor == "true") {
        onInteractiveElement(e, cursorElement, cursorShadowElement);
        rotateCursor.play();
      } else {
        onNotInteractiveElement(e, cursorElement, cursorShadowElement);
        rotateCursor.pause();
      }
    }
  };
  var onClick = function onClick() {
    gsap.to(cursorShadowElement, {});
  };
  var onQueryMatch = function onQueryMatch() {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("click", onClick);
  };
  var onQueryNotMatch = function onQueryNotMatch() {
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("click", onClick);
    rotateCursor.pause();
  };
  addMediaQueryListener("(hover: hover)", onQueryMatch, onQueryNotMatch);
};