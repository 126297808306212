import { applyColorScheme } from "./schemes.js";
var COLOR_MODE = "COLOR_MODE";
var DARK_MODE = "DARK";
var LIGHT_MODE = "LIGHT";
var lightModeButton = document.querySelector(".colorModeToggler__item--lightMode");
var darkModeButton = document.querySelector(".colorModeToggler__item--darkMode");
var setDarkMode = function setDarkMode() {
  localStorage.setItem(COLOR_MODE, DARK_MODE);
  lightModeButton.classList.remove("active");
  darkModeButton.classList.add("active");
  applyColorScheme("dark");
};
var setLightMode = function setLightMode() {
  localStorage.setItem(COLOR_MODE, LIGHT_MODE);
  lightModeButton.classList.add("active");
  darkModeButton.classList.remove("active");
  applyColorScheme("light");
};
export var handleColorSchemes = function handleColorSchemes() {
  lightModeButton.addEventListener("click", setLightMode);
  darkModeButton.addEventListener("click", setDarkMode);
  var colorMode = localStorage.getItem(COLOR_MODE);
  if (colorMode === LIGHT_MODE) {
    setLightMode();
  } else {
    setDarkMode();
  }
};