import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { pageWrapper, nextSection, prevSection } from "../index.js";
var scrollableElements = _toConsumableArray(document.querySelectorAll(".scrollable"));
export var handleTouch = function handleTouch() {
  var scroll = false;
  var touchStartPosition = 0;
  var distance = 0;
  var onTouchStart = function onTouchStart(e) {
    touchStartPosition = e.touches[0].clientY;
    scroll = true;
    scrollableElements.forEach(function (element) {
      if (element.contains(e.target)) {
        if (element.scrollHeight > element.clientHeight) {
          scroll = false;
        }
      }
    });
  };
  var onTouchMove = function onTouchMove(e) {
    var touchEndPosition = e.touches[0].clientY;
    distance = touchEndPosition - touchStartPosition;
  };
  var onTouchEnd = function onTouchEnd() {
    if (scroll) {
      if (distance < -70) {
        nextSection();
      } else if (distance > 70) {
        prevSection();
      }
    }
    distance = 0;
  };
  pageWrapper.addEventListener("touchstart", onTouchStart, {
    passive: true
  });
  pageWrapper.addEventListener("touchmove", onTouchMove, {
    passive: true
  });
  pageWrapper.addEventListener("touchend", onTouchEnd, {
    passive: true
  });
};