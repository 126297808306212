import { pageWrapper, nextSection, prevSection } from "../index.js";
export var handleWheel = function handleWheel() {
  var onWheel = function onWheel(e) {
    e.preventDefault();
    if (e.deltaY > 30 || e.deltaY === 3) {
      nextSection();
    } else if (e.deltaY < -30 || e.deltaY === -3) {
      prevSection();
    }
  };
  pageWrapper.addEventListener("wheel", onWheel);
};