import gsap, { ScrollToPlugin } from "../../../../_snowpack/pkg/gsap/all.js";
gsap.registerPlugin(ScrollToPlugin);
export var scrollAnimation = function scrollAnimation(section, onComplete) {
  gsap.to(window, {
    scrollTo: {
      y: section.offsetTop
    },
    duration: 1,
    onComplete: onComplete
  });
};