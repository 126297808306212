import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { addAllSectionsScrollListener } from "../../utils/sectionScrollListener.js";
import { backgroundTextChange, closeMenu, openMenu } from "./actions.js";
export var burger = document.querySelector(".burger");
var menu = document.querySelector(".mobileMenu");
var menuItems = _toConsumableArray(document.querySelectorAll(".mobileMenu__item"));
var backgroundText = document.querySelector(".mobileMenu__backgroundText");
var isMobileMenuOpen = false;
var onBurgerClick = function onBurgerClick() {
  if (!isMobileMenuOpen) {
    burger.classList.add("active");
    openMenu(menu, menuItems);
  } else {
    burger.classList.remove("active");
    closeMenu(menu, menuItems);
  }
  isMobileMenuOpen = !isMobileMenuOpen;
};
export var handleMobileNavigation = function handleMobileNavigation() {
  var onScroll = function onScroll(from, to) {
    var itemToDisable = menuItems.find(function (item) {
      return item.dataset.navigation === from;
    });
    var itemToEnable = menuItems.find(function (item) {
      return item.dataset.navigation === to;
    });
    if (itemToDisable && itemToEnable) {
      itemToDisable.classList.remove("active");
      itemToEnable.classList.add("active");
      burger.classList.remove("active");
      backgroundTextChange(itemToEnable, backgroundText);
      closeMenu(menu, menuItems);
    }
  };
  addAllSectionsScrollListener(onScroll);
  burger.addEventListener("click", onBurgerClick);
  menuItems.forEach(function (item) {
    item.addEventListener("click", function () {
      return closeMenu(menu, menuItems);
    });
  });
};