import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { isSectionId } from "../../../utils/isSectionId.js";
import { dispatchScrollChange } from "../../../utils/sectionScrollListener.js";
import { handleControls } from "./controls/index.js";
import { handleNavigateButtons } from "./navigateButtons.js";
import { scrollAnimation } from "./scrollAnimation.js";
import { hasPageBeenRefreshed } from "../../../utils/hasPageBeenRefreshed.ts.js";
export var pageWrapper = document.querySelector(".wrapper");
var sections = _toConsumableArray(document.querySelectorAll(".section"));
var currentSectionIdx = 0;
var isScrolling = false;
var onScrollComplete = function onScrollComplete() {
  return isScrolling = false;
};
var getSectionId = function getSectionId(idx) {
  return sections[idx].id;
};
export var scrollToSectionByIdx = function scrollToSectionByIdx(sectionIdx) {
  var isScrollingEnable = sectionIdx >= 0 && sectionIdx < sections.length && !isScrolling;
  if (isScrollingEnable) {
    var fromSectionIdentifier = getSectionId(currentSectionIdx);
    var toSectionIdentifier = getSectionId(sectionIdx);
    if (isSectionId(fromSectionIdentifier) && isSectionId(toSectionIdentifier)) {
      currentSectionIdx = sectionIdx;
      var currentSection = sections[currentSectionIdx];
      isScrolling = true;
      dispatchScrollChange(fromSectionIdentifier, toSectionIdentifier);
      scrollAnimation(currentSection, onScrollComplete);
      sessionStorage.setItem("sectionIdx", String(sectionIdx));
    }
  }
};
export var scrollToSectionByIdentifier = function scrollToSectionByIdentifier(sectionIdentifier) {
  var sectionIdx = sections.findIndex(function (section) {
    return section.getAttribute("id") === sectionIdentifier;
  });
  if (sectionIdx != -1) {
    scrollToSectionByIdx(sectionIdx);
  }
};
export var prevSection = function prevSection() {
  return scrollToSectionByIdx(currentSectionIdx - 1);
};
export var nextSection = function nextSection() {
  return scrollToSectionByIdx(currentSectionIdx + 1);
};
var setInitialScrollPosition = function setInitialScrollPosition() {
  if (hasPageBeenRefreshed()) {
    var currentSectionBeforeReloadIdx = sessionStorage.getItem("sectionIdx");
    if (currentSectionBeforeReloadIdx) {
      scrollToSectionByIdx(Number(currentSectionBeforeReloadIdx));
    }
  } else {
    scrollToSectionByIdentifier("start");
  }
};
export var getCurrentSection = function getCurrentSection() {
  return getSectionId(currentSectionIdx);
};
export var runCustomScroll = function runCustomScroll() {
  handleControls();
  handleNavigateButtons();
  setInitialScrollPosition();
  window.addEventListener("resizefixvh", function () {
    scrollAnimation(sections[currentSectionIdx], onScrollComplete);
  });
};