import { runCustomScroll } from "./global/customScroll/index.js";
import { handleCustomCursor } from "./global/customCursor/index.js";
import { handleColorSchemes } from "./global/colorScheme/index.js";
import { runOnHoverTextEffect } from "./global/onHoverTextEffect/index.js";
import { handleKeyboardNavigation } from "./global/keyboardNavigation/index.js";
import { handleLazyFeatures } from "../lazy/index.js";
export var runFeatures = function runFeatures() {
  runCustomScroll();
  handleCustomCursor();
  handleColorSchemes();
  handleKeyboardNavigation();
  runOnHoverTextEffect();
  window.addEventListener("load", function () {
    handleLazyFeatures();
  });
};