import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { isSectionId } from "../../../utils/isSectionId.js";
import { scrollToSectionByIdentifier } from "./index.js";
var navigationsItems = _toConsumableArray(document.querySelectorAll("[data-navigation]"));
export var handleNavigateButtons = function handleNavigateButtons() {
  navigationsItems.forEach(function (item) {
    var navData = item.dataset.navigation;
    if (navData && isSectionId(navData)) {
      item.addEventListener("click", function () {
        return scrollToSectionByIdentifier(navData);
      });
    }
  });
};