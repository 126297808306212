export var background = function background(section) {
  return import("../features/aboveTheFold/background/index.js").then(function (_ref) {
    var setBackgroundAnimation = _ref.setBackgroundAnimation;
    setBackgroundAnimation();
    section.classList.remove("loading");
  });
};
export var about = function about(section) {
  return import("../features/about/index.js").then(function (_ref2) {
    var runAboutSectionEffects = _ref2.runAboutSectionEffects;
    runAboutSectionEffects();
    section.classList.remove("loading");
  });
};
export var portfolio = function portfolio(section) {
  return import("../features/portfolio/index.js").then(function (_ref3) {
    var runPortfolioEffects = _ref3.runPortfolioEffects;
    runPortfolioEffects();
    section.classList.remove("loading");
  });
};
export var technologies = function technologies(section) {
  return import("../features/technologies/index.js").then(function (_ref4) {
    var runTechnologiesEffects = _ref4.runTechnologiesEffects;
    runTechnologiesEffects();
    section.classList.remove("loading");
  });
};
export var contact = function contact(section) {
  return import("../features/contact/index.js").then(function (_ref5) {
    var runContactFeatures = _ref5.runContactFeatures;
    runContactFeatures();
    section.classList.remove("loading");
  });
};