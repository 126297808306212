import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
import { handleNavigations } from "./navigations/index.js";
import { runFeatures } from "./features/index.js";
import { fixVh } from "./utils/fixVh.js";
import { handleError } from "./handleError/index.js";
try {
  fixVh();
  handleNavigations();
  runFeatures();
} catch (e) {
  if (__SNOWPACK_ENV__.MODE !== "development") {
    handleError(e);
  }
}