import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { addAllSectionsScrollListener } from "../../../utils/sectionScrollListener.js";
var pageWrapper = document.querySelector(".wrapper");
var sections = _toConsumableArray(document.querySelectorAll(".section"));
var onTabPress = function onTabPress(e) {
  if (e.key === "Tab") {
    pageWrapper.classList.add("tabbing");
    window.removeEventListener("keydown", onTabPress);
    window.addEventListener("mousedown", onMouseDown);
  }
};
var onMouseDown = function onMouseDown() {
  pageWrapper.classList.remove("tabbing");
  window.removeEventListener("mousedown", onMouseDown);
  window.addEventListener("keydown", onTabPress);
};
var setTabIndexesOnFocusableElements = function setTabIndexesOnFocusableElements(section, value) {
  var elements = section.querySelectorAll('[data-keyboard-focus="true"]');
  elements.forEach(function (el) {
    return el.setAttribute("tabindex", value);
  });
};
var onSectionChange = function onSectionChange(from, to) {
  var fromSection = sections.find(function (section) {
    return section.id === from;
  });
  var toSection = sections.find(function (section) {
    return section.id === to;
  });
  if (fromSection && toSection) {
    setTabIndexesOnFocusableElements(fromSection, "-1");
    setTabIndexesOnFocusableElements(toSection, "0");
  }
};
export var handleKeyboardNavigation = function handleKeyboardNavigation() {
  window.addEventListener("keydown", onTabPress);
  setTabIndexesOnFocusableElements(document.body, "-1");
  addAllSectionsScrollListener(onSectionChange);
};