export var addMediaQueryListener = function addMediaQueryListener(query, onMatch, onNotMatch) {
  var media = window.matchMedia(query);
  var onMediaChange = function onMediaChange(matches) {
    if (matches) {
      onMatch();
    } else {
      onNotMatch();
    }
  };
  var onMediaChangeListener = function onMediaChangeListener(e) {
    return onMediaChange(e.matches);
  };
  media.addEventListener("change", onMediaChangeListener);
  onMediaChange(media.matches);
  return function () {
    media.removeEventListener("change", onMediaChangeListener);
  };
};