export var splitText = function splitText(selector) {
  var element = document.querySelector(selector);
  if (element && element.textContent) {
    var characters = element.textContent.split("");
    var characterElements = [];
    var fragment = document.createDocumentFragment();
    characters.forEach(function (_char) {
      var charWrapper = document.createElement("span");
      charWrapper.style.display = _char != " " ? "inline-block" : "inline";
      charWrapper.textContent = _char;
      characterElements.push(charWrapper);
      fragment.appendChild(charWrapper);
    });
    element.innerHTML = "";
    element.appendChild(fragment);
    return characterElements;
  }
  throw Error("Element with ".concat(selector, " doesn't have any text"));
};